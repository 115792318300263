import React, { useEffect } from 'react'
import { Form, Row, Col, Button, Input, Select } from 'antd'
import { Formik, Field } from 'formik';
import { render, required } from '../../../utils/form'
import { LeftOutlined } from '@ant-design/icons';
const InputField = render(Input)
const SelectField = render(Select)

const BankDetails = ({t, goBack, submitBankDetails }) => {

  return (
    <div className="bankdetails">
      <div className="back" onClick={goBack}>
        <LeftOutlined /> {t('Back')}
      </div>
      <h1>{t('Get paid in 45 days')}</h1>
      <p>{t('Please provide your bank details by filling in the fields below')}</p>
      <Formik
        onSubmit={submitBankDetails}
        validateOnChange={false}
        initialValues={{
          bank: '',
          account_number: '',
          account_holder: ''
        }}
      >
        {({
          handleSubmit,
          errors,
          handleChange
        }) => (
          <Form onSubmit={handleSubmit} layout="vertical">
            <Row>
              <Col xs={24}>
                <Field
                  label={t("Bank")}
                  name={"bank"}
                  type="select"
                  labelAddon={t("COMPULSORY")}
                  component={SelectField}
                  error={errors.bank}
                  onChange={val => handleChange({ target: { name: 'bank', value: val } })}
                >
                  {banks.map(bank => <Option value={bank}>{bank}</Option>)}
                </Field>
              </Col>
              <Col xs={24} md={12} className="account">
                <Field
                  label={t('Account no.')}
                  name={"account_number"}
                  type="text"
                  labelAddon={t('COMPULSORY')}
                  component={InputField}
                  error={errors.account_number}
                  validate={required}
                />
              </Col>
              <Col xs={24} md={12} className="account-holder">
                <Field
                  label={t('Name of account holder')}
                  name={"account_holder"}
                  type="text"
                  labelAddon={t('OPTIONAL')}
                  component={InputField}
                  error={errors.account_holder}
                />
              </Col>
            </Row>
            <div className="buttons">
              <Button onClick={handleSubmit} className="primary-medium-2">{t('Submit')}</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default BankDetails;

let banks = ["Maybank", "CIMB Bank", "RHB Bank", "Bank Islam", "Bank Muamalat", "Bank Rakyat", "Bank Simpanan Nasional", "Citibank", "Hong Leong Bank", "HSBC Bank", "OCBC Bank", "Public Bank", "Affin Bank", "AmBank", "Agro Bank", "Alliance Bank", "Al-Rajhi Bank", "Bank of China", "Bank of America", "Bank of Tokyo-Mitsubishi UFJ", "BNP Paribas", "Deutsche Bank", "Industrial & Commercial Bank of China", "JP Morgan Chase Bank", "Kuwait Finance House", "Mizuho Bank", "Standard Chartered Bank", "Sumitomo Mitsui Banking Corporation", "The Royal Bank of Scotland", "United Overseas Bank"]