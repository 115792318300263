import example1 from './1.png'
import example2 from './2.png'
import example3 from './3.png'

export default [
  {
    page: 'dashboard',
    title: 'Dashboard',
    images: [example1],
    shortText: 'Nunc metus ac non tellus nibh tempus. Enim faucibus in fusce viverra in pulvinar duis. Nunc ultrices commodo, sed at ultricies in cursus. Sagittis sem quisque nisl, porta non, mauris a, et dui. Placerat laoreet senectus nulla in a. Iaculis elit, sit sit nam ornare sollicitudin.'
  },
  {
    page: 'opportunities',
    title: 'Opportunities',
    images: [example1, example2, example3],
    shortText: 'Nunc metus ac non tellus nibh tempus. Enim faucibus in fusce viverra in pulvinar duis. Nunc ultrices commodo, sed at ultricies in cursus. Sagittis sem quisque nisl, porta non, mauris a, et dui. Placerat laoreet senectus nulla in a. Iaculis elit, sit sit nam ornare sollicitudin.'
  },
  {
    page: 'products',
    title: 'Products',
    images: [example1, example2, example3],
    longText: 'Tellus nibh venenatis elit eros. Diam volutpat euismod eu ullamcorper turpis. Amet dolor viverra in sed neque at at. Turpis turpis magna varius ornare nisl eu id egestas.\nAliquet vitae vulputate quam etiam donec eget est.\nAt neque nec sit massa sit dui risus, ornare porttitor. Integer donec amet tellus ligula. Amet eu tortor dolor mauris.\nNon tortor vitae orci, sit massa etiam curabitur\nTurpis felis sit mi suspendisse viverra. At nulla ornare faucibus vulputate amet.\nErat eu, egestas aliquam mauris, orci eu eros quis. Hendrerit pellentesque vitae fermentum.\nAliquam varius augue turpis interdum urna elit nunc turpis aliquam. Ultrices ut quam volutpat sagittis, a. Vehicula interdum consequat, hendrerit velit. Lorem elit ac varius ac id purus id. Id nunc rutrum cras morbi adipiscing arcu dignissim dolor risus.'
  },
  {
    page: 'commissions',
    title: 'Commissions',
    images: [example1, example2],
    steps: [
      {
        title: 'Title of the step appears hear',
        text: 'Tellus nibh venenatis elit eros. Diam volutpat euismod eu ullamcorper turpis. Amet dolor viverra in sed neque at at. Turpis turpis magna varius ornare nisl eu id egestas.\nAliquet vitae vulputate quam etiam donec eget est.\nAt neque nec sit massa sit dui risus, ornare porttitor. Integer donec amet tellus ligula. Amet eu tortor dolor mauris.\nNon tortor vitae orci, sit massa etiam curabitur\nTurpis felis sit mi suspendisse viverra. At nulla ornare faucibus vulputate amet.\nErat eu, egestas aliquam mauris, orci eu eros quis. Hendrerit pellentesque vitae fermentum.\nAliquam varius augue turpis interdum urna elit nunc turpis aliquam. Ultrices ut quam volutpat sagittis, a. Vehicula interdum consequat, hendrerit velit. Lorem elit ac varius ac id purus id. Id nunc rutrum cras morbi adipiscing arcu dignissim dolor risus.'
      },
      {
        title: 'Title of the step appears hear',
        text: 'Tellus nibh venenatis elit eros. Diam volutpat euismod eu ullamcorper turpis. Amet dolor viverra in sed neque at at. Turpis turpis magna varius ornare nisl eu id egestas.\nAliquet vitae vulputate quam etiam donec eget est.\nAt neque nec sit massa sit dui risus, ornare porttitor. Integer donec amet tellus ligula. Amet eu tortor dolor mauris.\nNon tortor vitae orci, sit massa etiam curabitur\nTurpis felis sit mi suspendisse viverra. At nulla ornare faucibus vulputate amet.\nErat eu, egestas aliquam mauris, orci eu eros quis. Hendrerit pellentesque vitae fermentum.\nAliquam varius augue turpis interdum urna elit nunc turpis aliquam. Ultrices ut quam volutpat sagittis, a. Vehicula interdum consequat, hendrerit velit. Lorem elit ac varius ac id purus id. Id nunc rutrum cras morbi adipiscing arcu dignissim dolor risus.'
      },
      {
        title: 'Title of the step appears hear',
        text: 'Tellus nibh venenatis elit eros. Diam volutpat euismod eu ullamcorper turpis. Amet dolor viverra in sed neque at at. Turpis turpis magna varius ornare nisl eu id egestas.\nAliquet vitae vulputate quam etiam donec eget est.\nAt neque nec sit massa sit dui risus, ornare porttitor. Integer donec amet tellus ligula. Amet eu tortor dolor mauris.\nNon tortor vitae orci, sit massa etiam curabitur\nTurpis felis sit mi suspendisse viverra. At nulla ornare faucibus vulputate amet.\nErat eu, egestas aliquam mauris, orci eu eros quis. Hendrerit pellentesque vitae fermentum.\nAliquam varius augue turpis interdum urna elit nunc turpis aliquam. Ultrices ut quam volutpat sagittis, a. Vehicula interdum consequat, hendrerit velit. Lorem elit ac varius ac id purus id. Id nunc rutrum cras morbi adipiscing arcu dignissim dolor risus.'
      }
    ]
  },
]