import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'
import Header from '../common/header'
import Tutorials from '../common/tutorial/tutorial'
import { appWithTranslation } from '../../i18next'
import { useRouter } from 'next/router'
import ChoosePayout from '../common/ui_components/choosePayout'

const DefaultLayout = ({ children, auth  }) => {

  const router = useRouter()
  const [ tutorialPage, setTutorialPage ] = useState()
  const [ payoutModalVisible, setPayoutModalVisible ] = useState()

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      auth
    })
  );

  useEffect(() => {
    if (auth.user && !auth.user.dontShowTutorial && (auth.user.role === 'agent' || auth.user.role === 'reseller') &&  router.pathname !== '/basic') {
      setTutorialPage(router.pathname.replace('/', ''))
    }
  }, [router.pathname])

  useEffect(() => {
    if (auth.user && !auth.user.payoutMode && payoutModalVisible !== false) {
      setPayoutModalVisible(true)
    }
  }, [auth])



  return (
    <div style={{ minHeight: '100vh' }}>
      <Layout>
        <Header 
          auth={auth} 
          openTutorial={() => setTutorialPage(router.pathname.replace('/', ''))} 
        />
        {childrenWithProps}
      </Layout>
      <Tutorials
        auth={auth}
        page={tutorialPage}
        closeModal={() => setTutorialPage(false)}
      />
      <ChoosePayout
        isVisible={payoutModalVisible}
        closeModal={() => setPayoutModalVisible(false)}
        uid={auth.user && auth.user.uid}
      />
    </div>
  )

}

export default appWithTranslation(DefaultLayout);
