

import commercial from '../../../assets/illustrations/menu-commercial.svg'
import financial from '../../../assets/illustrations/menu-financial.svg'
import administrative from '../../../assets/illustrations/menu-administrative.svg'

export const languages = [
  { code: 'en', name: 'English' },
  { code: 'zh', name: 'Mandarin' },
  { code: 'ms', name: 'Bahasa Melayu' }
]

export const pageNames = {
  ['/']: 'Dashboard',
  ['/users']: 'Users',
  ['/opportunities']: 'Opportunities',
  ['/opportunities/[id]']: 'Opportunities',
  ['/products']: 'Products',
  ['/products/[productId]']: 'Products',
  ['/resellers']: 'Resellers',
  ['/resellers/[resellerId]']: 'Resellers',
  ['/promotions']: 'Promotions',
  ['/help']: 'Help',
  ['/commissions']: 'Commissions'
}

export const submenuItems = {
  commercial: {
    image: commercial,
    items: [
      {
        name: 'Resellers',
        link: '/resellers',
        role: ['admin', 'superadmin', 'agent'],
        icon: 'flaticon-customer',
        description: 'Manage your customers and invite them as resellers to boost your sales and revenue. You can also directly invite your existing resellers to reflect your current operations.',
        subpoints: [
          {
            name: 'Invite a reseller',
            link: '/resellers'
          }
        ]
      },
      {
        name: 'Opportunities',
        link: '/opportunities',
        icon: 'flaticon-funnel',
        role: ['admin', 'superadmin', 'agent'],
        description: 'Manage and track your leads within a straightforward sales funnel and efficiently convert your open opportunities to closed orders.',
        subpoints: [
          {
            name: 'Create a new opportunity',
            link: '/opportunities/new'
          },
          {
            name: 'View archived opportunities',
            link: '/opportunities?archived=true'
          },
          {
            name: 'View existing customers',
            link: '/customers'
          }
        ]
      },
      {
        name: 'Products',
        link: '/products',
        icon: 'flaticon-box',
        role: ['admin', 'superadmin'],
        description: 'Learn more about the products you will be selling from FGuide and the commissions you will be earning from them. You can also add and sell your own products.',
        subpoints: [
          {
            name: 'Create your own product',
            link: '/products/new'
          },
          {
            name: 'View the marketplace',
            link: '/products?view=marketplace'
          },
        ]
      },
      {
        name: 'Commissions',
        link: '/commissions',
        icon: 'flaticon-finance',
        role: ['agent'],
        description: 'Be on top of how much you owe and to who. Never miss your dues to agents and resellers, strengthening your trustworthiness as a paymaster.'
      }
    ]
  },
  financial: {
    image: financial,
    items: [
      {
        name: 'Payments',
        link: '/payments',
        icon: 'flaticon-coin-stack',
        role: ['admin', 'superadmin', 'agent'],
        description: 'Track received payments and due monthly instalments. Send reminders to customers with overdue payments or settle up instalment plans that can be cleared in full.'
      },
      {
        name: 'Commissions',
        link: '/commissions',
        icon: 'flaticon-finance',
        role: ['admin', 'superadmin'],
        description: 'Be on top of how much you owe and to who. Never miss your dues to agents and resellers, strengthening your trustworthiness as a paymaster.'
      }
    ]
  },
  administrative: {
    image: administrative,
    items: [
      {
        name: 'Users',
        link: '/users',
        icon: 'flaticon-avatar',
        role: ['superadmin'],
        description: 'Manage the permissions and access of key users of the platform. Invite admins and agents as you see fit and suspend their account when in breach of the terms and conditions.',
        subpoints: [
          {
            name: 'Invite an admin',
            link: '/users?invite=admin'
          },
          {
            name: 'Invite an agent',
            link: '/users?invite=agent'
          }
        ]
      },
      {
        name: 'Users',
        link: '/users',
        icon: 'flaticon-avatar',
        role: ['admin'],
        description: 'Manage the permissions and access of key users of the platform. Invite admins and agents as you see fit and suspend their account when in breach of the terms and conditions.',
        subpoints: [
          {
            name: 'Invite an agent',
            link: '/users?invite=agent'
          }
        ]
      },
      {
        name: 'Promotions',
        link: '/promotions',
        icon: 'flaticon-discount',
        description: 'Boost and promote the sale of specific products by offering them at a discounted price for set duration of time.',
        subpoints: [
          {
            name: 'Create a new promotion',
            link: '/promotions/new'
          },
        ]
      },
    ]
  }
}

export const menuPoints = [
  {
    route: '/',
    name: 'Dashboard',
    icon: 'flaticon-speedometer',
    roles: ['admin', 'superadmin', 'agent', 'reseller']
  },
  {
    route: '/resellers',
    name: 'Resellers',
    icon: 'flaticon-customer',
    roles: ['admin', 'superadmin', 'agent']
  },
  {
    route: '/opportunities',
    name: 'Opportunities',
    icon: 'flaticon-funnel',
    roles: ['admin', 'superadmin', 'agent', 'reseller']
  },
  {
    route: '/opportunities?archived=true',
    name: 'Archived opportunities',
    roles: ['admin', 'superadmin', 'agent', 'reseller']
  },
  {
    route: '/products',
    name: 'Products',
    icon: 'flaticon-box',
    roles: ['admin', 'superadmin', 'agent', 'reseller']
  },
  {
    route: '/payments',
    name: 'Payments',
    icon: 'flaticon-coin-stack',
    roles: ['admin', 'superadmin']
  },
  {
    route: '/commissions',
    name: 'Commissions',
    icon: 'flaticon-finance',
    roles: ['admin', 'superadmin', 'agent', 'reseller']
  },
  {
    route: '/promotions',
    name: 'Promotions',
    icon: 'flaticon-discount',
    roles: ['admin', 'superadmin']
  },
  {
    route: '/users',
    name: 'Users',
    icon: 'flaticon-avatar',
    roles: ['admin', 'superadmin']
  },
]