import { gql } from '@apollo/client';
import { apolloClient } from '../utils/graphql'

export const doLogin = async (username, password) => {
  let { data } = await apolloClient.query({
    query: LOGIN,
    variables: { username, password },
  })
  if (data.login) {
    if (!data.login.isSuspended && localStorage) {
      localStorage.setItem("access_token", data.login.access_token);
      localStorage.setItem("refresh_token", data.login.refresh_token);
    }
    trackLogin(username)
    return data.login
  } else {
    return null
  }
}

const trackLogin = username => {
  if (typeof ga === 'function') {
    const trackerName = ga.getAll()[0].get('name');
    ga(`${trackerName}.send`, {
      'hitType': 'event',         
      'eventCategory': 'Login', 
      'eventAction': 'Login',     
      'eventLabel': username
    });
  }
}

const LOGIN = gql`
  query Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      role
      name
      email
      uid
      access_token
      refresh_token
      isSuspended
      seenWelcomeModal
      seenAdvanced
      mode
      payoutMode
      dontShowTutorial
    }
  }
`



export const getUser = async () => {
  let { data } = await apolloClient.query({ query: GET_USER })
  return data.getUser
}

const GET_USER = gql`
  query GetUser {
    getUser {
      name,
      company,
      role,
      email,
      status
      phone
      uid
      mode
      seenWelcomeModal
      bank
      payoutMode
      seenAdvanced
      dontShowTutorial
    }
  }
`

export const doRegister = async (values) => {
  try {
    let { data } = await apolloClient.mutate({
      mutation: ADD_USER,
      variables: values,
    })
    return data
  } catch (err) {
    console.log('error registering', err)
    return err
  }
}


const ADD_USER = gql`
  mutation AddUser(
    $firstName: String!,
    $lastName: String!,
    $password: String!,
    $email: String!
  ) {
    addUser(user: {
      firstName: $firstName,
      lastName: $lastName,
      password: $password,
      email: $email,
      role: "admin"
    }) {
      uid
    }
  }
`