import React from 'react'
import './drawer.module.scss';
import { Drawer } from 'antd'

const MobileDrawer = ({className, isOpen, buttons, title, t, onClose, footer }) => (
  <Drawer
    title={null}
    placement="bottom"
    height={className === 'contact-drawer' ? 420 : undefined}
    closable={false}
    onClose={onClose}
    className={className ? `mobile-drawer ${className}` : 'mobile-drawer'}
    visible={isOpen}
  >
    <div className="head">
      <p>{title || ''}</p>
      <p onClick={onClose}>{t('Cancel')}</p>
    </div>
    <div>
      {buttons.filter(item => !item.hidden).map(btn => {
        if (btn.content) {
          return btn.content
        } else if (btn.isLabel) {
          return <label>{btn.text}</label>
        } else {
          return (
            <div
              onClick={() => {
                btn.action()
                onClose()
              }}
              className={`drawer-button ${btn.className || ''}`}
            >
              {btn.icon && typeof btn.icon === 'string' && <i className={`fa ${btn.icon}`} />}
              {btn.icon && typeof btn.icon !== 'string' && btn.icon}
              {btn.text}
            </div>
          )
        }
      })}
      {footer && footer}
    </div>
  </Drawer>
)

export default MobileDrawer;