import React from 'react'
import { Layout } from 'antd'
import { appWithTranslation } from '../../i18next'

const EmptyLayout = ({ children, auth  }) => {

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      auth
    })
  );


  return (
    <div style={{ minHeight: '100vh' }}>
      <Layout>
        {childrenWithProps}
      </Layout>
    </div>
  )

}

export default appWithTranslation(EmptyLayout);
