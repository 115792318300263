import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, Form, Row, Col, Select } from 'antd'
import money from '../../../assets/illustrations/money.png'
import './accountModal.module.scss'
import { useRouter } from 'next/router'
import withApollo, { apolloClient } from '../../utils/graphql'
import { gql, useMutation } from "@apollo/client";
import { Formik, Field } from 'formik';
import { render, required, password } from '../../utils/form'
import { LeftOutlined } from '@ant-design/icons';
import { CheckCircleTwoTone } from '@ant-design/icons';
const InputField = render(Input)
const SelectField = render(Select)
const { Option } = Select
const AccountModal = ({ t, mode, setMode, uid }) => {

  const [ showPassword, setShowPassword ] = useState(true)
  const [ profile, setProfile ] = useState()
  const [ loading, setLoading ] = useState(false)

  const [updateUser] = useMutation(UPDATE_USER)
  const [changePass] = useMutation(CHANGE_PASSWORD)

  useEffect(async () => {
    if (mode) {
      let { data: userData } = await apolloClient.query({
        query: GET_USER_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
          id: uid
        }
      })
      setProfile(userData.getUserById)
      setTimeout(() => setShowPassword(false), 2000)
    }
  }, [mode])

  const updateProfile = async (values, { setErrors }) => {
    setLoading(true)
    try {
      await updateUser({variables: {
        uid,
        ...values
      }})
      setLoading(false)
      setMode('done')
      displayNotification(<p>{t('Your account details have been updated!')}</p>)
    } catch(err) {
      if (err.message.includes('AuthenticationError')) {
        setErrors({
          password: t('Invalid password'),
        })
        setLoading(false)
      }
    }
  }

  const changePassword = async (values, { setErrors }) => {
    setLoading(true)
    try {
      await changePass({variables: {
        uid,
        ...values
      }})
      setLoading(false)
      setMode('done')
    } catch(err) {
      if (err.message.includes('AuthenticationError')) {
        setErrors({
          currentPassword: t('Invalid password'),
        })
        setLoading(false)
      }
    }
  }

  const renderBody = () => {
    if (mode === 'password') {
      return (
        <div className="password">
          <h1>{t('Change password')}</h1>
          <Formik
            onSubmit={changePassword}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              currentPassword: '',
              password: ''
            }}
          >
            {({
              handleSubmit,
              errors,
            }) => (
              <Form onSubmit={handleSubmit} layout="vertical">
                <Row>
                  <Col xs={24}>
                    <div className="password-box">
                      <Field
                        role="presentation" 
                        autocomplete="off"
                        label={t("Current password")}
                        name="currentPassword"
                        type={showPassword ? "text" : "password"}
                        component={InputField}
                        labelAddon={t('COMPULSORY')}
                        error={errors.currentPassword}
                        validate={val => password(val, t)}
                      />
                      <span onClick={() => setShowPassword(!showPassword)} id="show-pass">{showPassword ? t('hide') : t('show')}</span>
                    </div>
                    <p>{t('Enter your current password in order to change to a new password.')}</p>
                  </Col>
                  <Col xs={24}>
                    <div className="password-box">
                      <Field
                        label={t("New password")}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        component={InputField}
                        labelAddon={t('COMPULSORY')}
                        error={errors.password}
                        validate={val => password(val, t)}
                      />
                      <span onClick={() => setShowPassword(!showPassword)} id="show-pass">{showPassword ? t('hide') : t('show')}</span>
                    </div>
                    <p>{t('Your new password must contain at least 8 characters and at least 1 number')}</p>
                  </Col>
                </Row>
                <div className="buttons">
                  <Button loading={loading} onClick={handleSubmit} className="primary-medium-2">{t('Submit')}</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )
    } else if (mode === 'profile') {
      return (
        <div className="profile">
          <h1>{t('Change account details')}</h1>
          <Formik
            onSubmit={updateProfile}
            validateOnChange={false}
            enableReinitialize={true}
            validateOnBlur={false}
            initialValues={ profile || {
              password: '',
              phone: '',
              email: '',
              password: ''
            }}
          >
            {({
              handleSubmit,
              errors,
            }) => (
              <Form onSubmit={handleSubmit} layout="vertical">
                <Row>
                  <Col xs={24}>
                    <Field
                      label={t('Name as per NRIC')}
                      name={"name"}
                      type="text"
                      labelAddon={t('COMPULSORY')}
                      component={InputField}
                      error={errors.name}
                      validate={required}
                    />
                  </Col>
                  <Col xs={24}>
                    <Field
                      label={t('Phone number')}
                      name={"phone"}
                      type="text"
                      labelAddon={t('COMPULSORY')}
                      component={InputField}
                      error={errors.phone}
                      validate={required}
                    />
                  </Col>
                  <Col xs={24}>
                    <Field
                      label={t('Email')}
                      name={"email"}
                      type="email"
                      labelAddon={t('COMPULSORY')}
                      component={InputField}
                      error={errors.email}
                      validate={required}
                    />
                  </Col>
                  <Col xs={24}>
                    <div className="password-box">
                      <Field
                        label={t("Password")}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        component={InputField}
                        labelAddon={t('COMPULSORY')}
                        error={errors.password}
                        validate={val => password(val, t)}
                      />
                      <span onClick={() => setShowPassword(!showPassword)} id="show-pass">{showPassword ? t('hide') : t('show')}</span>
                    </div>
                  </Col>
                </Row>
                <div className="buttons">
                  <Button loading={loading} onClick={handleSubmit} className="primary-medium-2">{t('Submit')}</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )
    } else if (mode === 'bank') {
      return (
        <div className="bank">
          <h1>{t('Change account details')}</h1>
          <Formik
            onSubmit={updateProfile}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={profile || {
              bank: '',
              account_number: '',
              account_holder: ''
            }}
          >
            {({
              handleSubmit,
              errors,
              handleChange
            }) => (
              <Form onSubmit={handleSubmit} layout="vertical">
                <Row>
                  <Col xs={24} md={12} className="bank">
                    <Field
                      label={t("Bank")}
                      name={"bank"}
                      type="select"
                      labelAddon={t("COMPULSORY")}
                      component={SelectField}
                      error={errors.bank}
                      onChange={val => handleChange({ target: { name: 'bank', value: val } })}
                    >
                      {banks.map(bank => <Option value={bank}>{bank}</Option>)}
                    </Field>
                  </Col>
                  <Col xs={24} md={12} className="account">
                    <Field
                      label={t('Account no.')}
                      name={"account_number"}
                      type="text"
                      labelAddon={t('COMPULSORY')}
                      component={InputField}
                      error={errors.account_number}
                      validate={required}
                    />
                  </Col>
                  <Col xs={24}className="account-holder">
                    <Field
                      label={t('Name of account holder')}
                      name={"account_holder"}
                      type="text"
                      labelAddon={t('OPTIONAL')}
                      component={InputField}
                      error={errors.account_holder}
                    />
                  </Col>
                </Row>
                <div className="buttons">
                  <Button onClick={handleSubmit} className="primary-medium-2">{t('Submit')}</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )
    } else if (mode === 'done') {
      return (
        <div className="success">
          <CheckCircleTwoTone twoToneColor="#52C41A" />
          <p>{t('Your details were successfully set up.')}</p>
          <Button style={{marginLeft: 'auto'}} onClick={() => setMode()} className="primary-medium-2">{t('Close')}</Button>
        </div>
      )
    }
  }

  return (
    <Modal
      wrapClassName="account-modal"
      visible={mode}
      onCancel={() => setMode()}
      footer={false}
      width={mode === 'bank' ? 620 : 405}
    >
      {renderBody()}
    </Modal>
  )
}

export default withApollo({ ssr: true })(AccountModal);

const UPDATE_USER = gql`
  mutation UdateUser(
    $uid: String,
    $name: String,
    $email: String,
    $phone: String,
    $password: String
    $bank: String,
    $account_number: String,
    $account_holder: String
  ) {
    updateUser(user: {
      uid: $uid,
      name: $name,
      email: $email,
      phone: $phone,
      password: $password
      bank: $bank,
      account_number: $account_number,
      account_holder: $account_holder
    })
  }
`
const GET_USER_BY_ID = gql`
  query GetUserById($id: String!) {
    getUserById(id: $id) {
      uid
      name
      company
      phone
      email
      stripeStatus
      role
      status
      stripeAccountId
      bank
      account_holder
      account_number
      payoutMode
    }
  }
`

const CHANGE_PASSWORD = gql`
  mutation changePass($uid: ID, $password: String, $currentPassword: String) {
    changePass(uid: $uid, password: $password, currentPassword: $currentPassword)
  }
`

let banks = ["Maybank", "CIMB Bank", "RHB Bank", "Bank Islam", "Bank Muamalat", "Bank Rakyat", "Bank Simpanan Nasional", "Citibank", "Hong Leong Bank", "HSBC Bank", "OCBC Bank", "Public Bank", "Affin Bank", "AmBank", "Agro Bank", "Alliance Bank", "Al-Rajhi Bank", "Bank of China", "Bank of America", "Bank of Tokyo-Mitsubishi UFJ", "BNP Paribas", "Deutsche Bank", "Industrial & Commercial Bank of China", "JP Morgan Chase Bank", "Kuwait Finance House", "Mizuho Bank", "Standard Chartered Bank", "Sumitomo Mitsui Banking Corporation", "The Royal Bank of Scotland", "United Overseas Bank"]