import React, { useEffect, useState } from 'react'
import '../common/styles/form.scss';
import 'antd/dist/antd.less';
import '../common/styles/common.scss';
import '../common/styles/buttons.scss';
import '../common/styles/typography.scss';
//import '../../assets/icons/flaticon.css';


import { layouts } from '../layouts'
import { useRouter } from 'next/router'
import NextNprogress from 'nextjs-progressbar';
import { doLogin, getUser } from '../utils/auth'

const getLayout = (router) => {

  if (router.pathname === '/signin' || router.pathname === '/cost-breakdown/[id]' || router.pathname === '/pay/[id]' || router.pathname === '/terms_and_conditions') {
    return layouts['EmptyLayout']
  } else {
    return layouts['DefaultLayout']
  }
}

function NextJSApp({ Component, pageProps }) {

  const router = useRouter()
  const Layout = getLayout(router)

  const [ user, setUser ] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(async () => {
    
    let user = await getUser()
    if (user) {
      setUser(user)
    } else if (!isAllowed()) {
      logout()
    }

    window.addEventListener('click',onClickButtons, true);
    checkWindowSize()
    window.addEventListener('resize',checkWindowSize, true);
    return () => {
      window.addEventListener('resize', checkWindowSize, true);
      window.removeEventListener('click',onClickButtons, true);
    }

  }, [])

  const onClickButtons = (e) => {
    let buttonName = e.target.getAttribute("data-title")
    if (typeof ga === 'function' && buttonName && (!user || (user.role === 'agent' || user.role === 'reseller'))) {
      const trackerName = ga.getAll()[0].get('name');
      ga(`${trackerName}.send`, {
        'hitType': 'event',         
        'eventCategory': 'Click', 
        'eventAction': buttonName
      });
    }
  }


  const isAllowed = () => {
    let nowAllowedPaths_agent = ['/users']
    let allowed_paths_withoutUser = ['/cost-breakdown/[id]', '/signin', '/pay/[id]', '/terms_and_conditions']
    if (user) {
      if (user.role === 'agent' && nowAllowedPaths_agent.includes(router.pathname)) {
        return false
      } else {
        return true
      }
    } else if (allowed_paths_withoutUser.includes(router.pathname))  {
      return true
    } else {
      return false
    }
  }

  const checkWindowSize = () => {
    if (!isMobile && window.innerWidth < 768) {
      setIsMobile(true)
    } else if (isMobile && window.innerWidth > 768) {
      setIsMobile(false)
    }
  }

  const logout = () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    setUser(null)
    router.push('/signin')
  }

  const login = async (username, password) => {
    try {
      let user = await doLogin(username, password)
      if (user) {
        if (!user.isSuspended) {
          setUser(user)
          router.push('/')
        }
      }
      return user
    } catch (err) {
      return err
    }
  }


  const auth = { user, login, logout, setUser }


  return (
    <Layout router={router} auth={auth}>
      <NextNprogress
        color="#003333"
        height="2"
        options={{
          showSpinner: false
        }}
      />
      {isAllowed()
        ? <Component
            {...pageProps}
            router={router}
            auth={auth}
            isMobile={isMobile}
          />
        : <div />
      }
    </Layout>
  )
}

export default NextJSApp
