import React, { useEffect } from 'react'
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button } from 'antd';

const Success = ({t, goBack, type }) => {

  return (
    <div className="stripe-success">
      <CheckCircleTwoTone twoToneColor="#52C41A" />
        <p>{type === 'stripe' ? t('Your Stripe account is connected to the platform.') : t('Your bank account was successfully set up.')}</p>
        <p>{type === 'stripe' ? t('You will now receive your commissions in 15 days only, everytime a customer pays for a product or opportunity you have shared.') : t('You will receive your first payment in 45 days.')}</p>
        <Button style={{marginLeft: 'auto'}} onClick={goBack} className="primary-medium-2">{t('Close')}</Button>
    </div>
  )
}

export default Success;

