import React, { useState, useEffect } from 'react'
import { gql } from "@apollo/client";
import { apolloClient } from '../../../utils/graphql'
import numeral from 'numeral';
import illustration from '../../../../assets/illustrations/reseller-basic-intro-4.png'

const formatNumber_round = (num) => numeral(num).format('0,0.00').split('.')[0]
const formatNumber_decimal = (num) => numeral(num).format('0,0.00').split('.')[1]


const ChoosePayout = ({t, setupStripe, enterBankDetails, amount }) => {

  return (
    <div className="choose-payout">
      <div className="inline">
        <div className="commission-amount">
          <p>{t('Commission payout we owe you')}</p>
          <div className="amount">
            <span>RM</span> {formatNumber_round(amount)}<span>.{formatNumber_decimal(amount)}</span>
          </div>
        </div>
        <img src={illustration} />
      </div>
      <p className="text">{t('Products that you have shared have converted to real purchases and it is time for you to collect your money. We need you to provide your bank details by choosing one of the payment terms below.')}</p>
      <div className="buttons">
        <Button type="auto" t={t} onClick={setupStripe} />
        <Button type="manual" t={t} onClick={enterBankDetails} />
      </div>
    </div>
  )
}

export default ChoosePayout;

const Button = ({type, t, onClick}) => (
  <div className={`button ${type}`} onClick={onClick}>
    <label>{t(type === 'auto' ? 'AUTOMATIC PAYMENTS' : 'MANUAL PAYMENTS')}</label>
    <h3>{t(type === 'auto' ? 'Get paid in 15 days' : 'Get paid in 45 days')}</h3>
    <p>{t(type === 'auto' ? 'Set up in 3 minutes' : 'Set up in 1 minute')}</p>
  </div>
)