export const sendPageView = async (role, title) => {
  try {
    if (typeof ga === 'function') {
      const trackerName = ga.getAll()[0].get('name');
      ga(`${trackerName}.set`, 'dimension1', role);
      ga(`${trackerName}.send`, 'pageview', title);
    } else {
      setTimeout(() => sendPageView(role, title), 500);
    }
  } catch {
    setTimeout(() => sendPageView(role, title), 1000);
  }
}

export const trackContact = button => {
  const trackerName = ga.getAll()[0].get('name');
  ga(`${trackerName}.send`, {
    'hitType': 'event',         
    'eventCategory': 'Contact', 
    'eventAction': button,     
  });
}