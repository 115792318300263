import React, { useState, useEffect } from 'react'
import { Layout, Button, Menu, Select, Popover, Row, Col } from 'antd'
import { withTranslation } from '../../../i18next'
import { WechatOutlined, WhatsAppOutlined, PhoneOutlined, QuestionCircleOutlined, MenuOutlined, CloseOutlined, ArrowLeftOutlined, CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router'
import logo from '../../../assets/logos/logo-crm-nav-desktop.svg'
import biglogo from '../../../assets/logos/logo-auth-desktop.png'
import { Desktop, Mobile } from '../../utils/responsive'
import Drawer from '../ui_components/drawer'
import { pathOr } from 'ramda'
import { languages, pageNames, submenuItems, menuPoints } from './menuData'
import './index.module.scss'
import BasicHeader from './basicHeader';
import compose from '../../utils/compose'
import Link from 'next/link'
import withApollo from '../../utils/graphql'
import { trackContact } from '../../utils/analytics'
import { gql, useQuery, useMutation } from "@apollo/client";
const { Header: AntHeader } = Layout;
const { Option } = Select
const { SubMenu } = Menu;

const Header = ({ auth, t, i18n, openTutorial }) => {

  const [currentLanguage, setCurrentLanguage] = useState('en')
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isContactDrawerOpen, setContactDrawerOpen ] = useState(false)

  const [ updateUser ] = useMutation(UPDATE_USER)

  const role = pathOr('agent',['user', 'role'], auth)
  const isAdmin = ['admin', 'superadmin'].includes(role)

  const router = useRouter()
  const { intro } = router.query

  useEffect(() => {
    let browswerLang = navigator.languages
      ? navigator.languages[0]
      : (navigator.language || navigator.userLanguage)

    browswerLang = browswerLang.includes('en') ? 'en' : browswerLang
    let lang = window.localStorage.language || browswerLang || 'en'
    if (!['en', 'zh', 'ms'].includes(lang)) lang = 'en'
    setCurrentLanguage(lang)

  }, [])

  useEffect(() => {
    if (auth.user && ['agent', 'reseller'].includes(role) && intro) {
      openHelpMenu()
    }
  }, [auth])

  const openHelpMenu = () => {
    let menuIcon = document.getElementsByClassName('anticon-question-circle')[0]
    menuIcon.click()
    //Darken layout
    let layout = document.getElementsByClassName('ant-layout')[0]
    layout.style.filter = 'brightness(0.5)'
  }

  const onCloseMenu = () => {
    let layout = document.getElementsByClassName('ant-layout')[0]
    layout.style.filter = ''
    updateUser({variables: {
      uid: auth.user.uid,
      seenAdvanced: true
    }})
  }

  const changeLanguage = lang => {
    i18n.changeLanguage(lang)
    setCurrentLanguage(lang)
    localStorage.setItem("language", lang);
  }

  const onClickContact = (button) => {
    trackContact(button)
    if (button === 'WhatsApp') {
      window.open('https://api.whatsapp.com/send?phone=+60183868889', '_blank')
    } else if (button === 'Phone') {
      window.open('tel:+60162943982')
    }
  }

  const switchToBasic = () => {
    updateUser({variables: {
      uid: auth.user.uid,
      mode: 'basic'
    }})
    router.push('/basic')

  }

  const helpMenu = (
    <div>
      <label>{t('HELP')}</label>
      <div onClick={() => openTutorial()}><QuestionCircleOutlined /> {t('Need help on section')}</div>
      <label>{t('CUSTOMER SERVICE')}</label>
      <div onClick={() => onClickContact('WhatsApp')}><WhatsAppOutlined /> {t('On')} WhatsApp</div>
      <div><WechatOutlined /> {t('On')} WeChat</div>
      <div onClick={() => onClickContact('Phone')}><PhoneOutlined /> 012 3456 7890</div>
      {role === 'reseller' && <label>{t('BASIC')}</label>}
      {role === 'reseller' && <div onClick={() => switchToBasic()}>{t('Switch to F-Guide Basic')}</div>}
      {auth.user && intro &&
        <div className="notice">
          <p>{t('You can switch back to basic at any by clicking the help icon in the top-right corner and then selecting ‘Switch to Basic’')}</p>
          <Button onClick={() => {
            let menuIcon = document.getElementsByClassName('anticon-question-circle')[0]
            menuIcon.click()
            onCloseMenu() 
          }}>
            {t('Dismiss')}
          </Button>
        </div>
      }
    </div>
  )

  const goToPage = route => {
    setIsOpen(false)
    router.push(route)
  }

  const renderSubmenu = point => (
    <Row className="submenu-opened">
      {submenuItems[point].items.filter(item => !item.role || item.role.includes(role)).map( item => (
        <Col xs={6} className="submenu-item">
          <Link href={item.link}>
            <div>
              <h3><i className={item.icon} />{t(item.name)}</h3>
              <p>{t(item.description)}</p>
            </div>
          </Link>
          {item.subpoints && item.subpoints
            .filter(point => !isAdmin || point.name !== 'Invite a reseller')
            .map( subItem => (
            <Link href={subItem.link}>
              <p className="submenu-link">
                {t(subItem.name)}
                <i className="flaticon-right-arrow" />
              </p>
            </Link>
          ))}
        </Col>
      ))}
      <Col xs={6} className="img-container">
        <img src={submenuItems[point].image} />
      </Col>
    </Row>
  )

  const adminMenu = (
    <Menu
      mode="horizontal"
      onClick={({ key }) => router.push(key)}
      selectedKeys={[`/${router.route.split('/')[1]}`]}
    >
      <Menu.Item key="/" icon={<i className="flaticon-speedometer" />}>
        {t('Dashboard')}
      </Menu.Item>
      <SubMenu
        key="commercial"
        icon={<i className="flaticon-shop" />}
        title={<div>{t('Commercial')} <CaretDownOutlined /></div>}
      >
        {renderSubmenu('commercial')}
      </SubMenu>
        <SubMenu
          key="financial"
          icon={<i className="flaticon-wallet" />}
          title={<div>{t('Financial')} <CaretDownOutlined /></div>}
        >
          {renderSubmenu('financial')}
        </SubMenu>
        <SubMenu
          key="administrative"
          icon={<i className="flaticon-avatar" />}
          title={<div>{t('Administrative')} <CaretDownOutlined /></div>}
        >
          {renderSubmenu('administrative')}
        </SubMenu>
    </Menu>
  )

  

  const agnentMenu = (
    <Menu
      mode="horizontal"
      onClick={({ key }) => router.push(key)}
      selectedKeys={[`/${router.route.split('/')[1]}`]}
      inlineCollapsed={false}
    >
      <Menu.Item key="/" icon={<i className="flaticon-speedometer" />}>
        {t('Dashboard')}
      </Menu.Item>
      <SubMenu
        key="commercial"
        icon={<i className="flaticon-shop" />}
        title={<div>{t('Commercial')} <CaretDownOutlined /></div>}
      >
        {renderSubmenu('commercial')}
      </SubMenu>
      <Menu.Item key="/products" icon={<i className="flaticon-box" />}>
        {t('Products')}
      </Menu.Item>
    </Menu>
  )

  const resellerMenu = (
    <Menu
      mode="horizontal"
      onClick={({ key }) => router.push(key)}
      selectedKeys={[`${router.route.split('/')[1]}`]}
      inlineCollapsed={false}
    >
      <Menu.Item key="/" icon={<i className="flaticon-speedometer" />}>
        {t('Dashboard')}
      </Menu.Item>
      <Menu.Item key="/opportunities" icon={<i className="flaticon-funnel" />}>
        {t('Opportunities')}
      </Menu.Item>
      <Menu.Item key="/products" icon={<i className="flaticon-box" />}>
        {t('Products')}
      </Menu.Item>
      <Menu.Item key="/commissions" icon={<i className="flaticon-finance" />}>
        {t('Commissions')}
      </Menu.Item>
    </Menu>
  )

  const menus = {
    admin: adminMenu,
    superadmin: adminMenu,
    agent: agnentMenu,
    reseller: resellerMenu
  }

  if (router.route === '/basic') {
    return (
      <BasicHeader 
        t={t} 
        changeLanguage={changeLanguage}
        currentLanguage={currentLanguage}
        onClickContact={onClickContact}
        logout={() => auth.logout()}
        user={auth.user}
        openTutorial={openTutorial}
      />
    )
  }

  return (
    <AntHeader className="header">

      <Desktop>
        <img src={logo} />
        {menus[role]}
        <div className="right-section">
          <Select 
            value={t('My Account')} 
            className="my-account"
            dropdownClassName="select-dropdown"
            onChange={async val => {
              if (val === 'account') {
                router.push('/account')
              } else {
                await auth.logout()
                router.push('/signin')
              }
            }}
          >
            <Option value="account" key="en">{t('Account settings')}</Option>
            <Option value="signout" key="en">{t('Sign out')}</Option>
          </Select>
          <Select
            dropdownClassName="select-dropdown"
            className="select-language"
            value={languages.find(lang => lang.code === currentLanguage).code}
            onChange={val => changeLanguage(val)}
          >
            {languages.map(lang => <Option value={lang.code} key={lang.code}>{lang.code}</Option>)}
          </Select>
          <Link href="/opportunities/new">
            <Button className='new-opportunity'>
              <PlusOutlined />
              {t('New opportunity')}
            </Button>
          </Link>
          <Popover 
            placement="bottomRight" 
            content={helpMenu} 
            overlayClassName="help-menu"
            trigger={['hover', 'click']}
            onVisibleChange={(visible) => {
              if (!visible && auth.user && ['agent', 'reseller'].includes(role) && intro) {
                onCloseMenu()
              }
            }}
          >
            <QuestionCircleOutlined />
          </Popover>
        </div>
      </Desktop>

      <Mobile>
        {router.query.name
          ? <div onClick={() => router.push(`/${router.route.split('/')[1]}`)} className="back"><ArrowLeftOutlined /></div>
          : <img src={logo} />
        }
        <div>
          <div>
            <h5>{t(pageNames[router.route])}</h5>
            {router.query.name && <p>{router.query.name}</p>}
          </div>
          <QuestionCircleOutlined onClick={() => setContactDrawerOpen(true)} />
          <MenuOutlined onClick={() => setIsOpen(true)} />
        </div>

        {isOpen &&
          <div className="mobile-menu-opened">
            <div className="content">
              <div className="inline">
                <img src={biglogo} />
                <CloseOutlined onClick={() => setIsOpen(false)} />
              </div>
              {menuPoints
              .filter(item => item.roles.includes(role))
                .map( item => (
                  <p onClick={() => goToPage(item.route)} className={`menuitem ${(router.asPath === item.route || router.route === item.route) && 'active'} ${!item.icon && 'indented'}`}>
                    <i className={item.icon} />
                    {t(item.name)}
                  </p>
              ))}
              <p 
                onClick={async () => {
                  await auth.logout()
                  router.push('/signin')
                }} 
                className="signout">
                  {t('Sign out')}
              </p>
              <div className="inline">
                <p onClick={() => goToPage('/account')} >{t('Account Settings')}</p>
                <Select
                  dropdownClassName="select-dropdown language in-nav"
                  className="select-language-mobile"
                  value={currentLanguage}
                  onChange={val => changeLanguage(val)}
                >
                  {languages.map(lang => <Option value={lang.code} key={lang.code}>{lang.code}</Option>)}
                </Select>
              </div>
            </div>
          </div>
        }

        <Drawer
          isOpen={isContactDrawerOpen}
          className="contact-drawer"
          buttons={[
            {
              isLabel: true,
              text: t('HELP')
            },
            {
              text: t('Need help on section'),
              icon: <QuestionCircleOutlined />,
              action: () => openTutorial()
            },
            {
              isLabel: true,
              text: t('CUSTOMER SERVICE')
            },
            {
              text: t('On Whatsapp'),
              icon: <WhatsAppOutlined />,
              action: () => onClickContact('WhatsApp')
            },
            {
              text: t('On Wechat'),
              icon: <WechatOutlined />,
              action: () => { }
            },
            {
              text: '012 2345 5678',
              icon: <PhoneOutlined />,
              action: () => onClickContact('Phone')
            },
            {
              isLabel: true,
              text: t('BASIC')
            },
            {
              text: t('Switch to F-Guide Basic'),
              action: () => switchToBasic(),
              hidden: role !== 'reseller'
            },
          ]}
          t={t}
          onClose={() => {
            setContactDrawerOpen(false)
            if (!visible && auth.user && ['agent', 'reseller'].includes(role) && intro) {
              onCloseMenu()
            }
          }}
          footer={auth.user && intro &&
            <div className="notice">
            <p>{t('You can switch back to basic at any by clicking the help icon in the top-right corner and then selecting ‘Switch to Basic’')}</p>
          </div>
          }
        />
      </Mobile>
    </AntHeader>
  )
}

Header.getInitialProps = async () => {
  return { namespacesRequired: ['all'] }
}

export default compose(
  withApollo({ ssr: true }),
  withTranslation(['all'])
)(Header)


const UPDATE_USER = gql`
  mutation UdateUser(
    $seenAdvanced: Boolean,
    $mode: String,
    $uid: String
  ) {
    updateUser(user: {
      seenAdvanced: $seenAdvanced,
      mode: $mode,
      uid: $uid
    })
  }
`