import React, { useState, useEffect } from 'react'
import { Layout, Button, Select, Popover } from 'antd'
import { RightOutlined, CaretDownOutlined, WhatsAppOutlined, PhoneOutlined } from "@ant-design/icons";
import './basicHeader.module.scss'
import { languages } from './menuData'
import logo from '../../../assets/logos/logo-crm-nav-desktop.png'
import avatar from '../../../assets/icons/avatar.svg'
import avatar_open from '../../../assets/icons/avatar_open.svg'
import rich from '../../../assets/icons/rich.svg'
import Drawer from '../ui_components/drawer'
import { Desktop, Mobile } from '../../utils/responsive';
import GoAdcancedModal from '../../components/basic/goAdvancedModal';
import AccountModal from '../../components/basic/accountModal';
import { apolloClient } from '../../utils/graphql'
import { gql } from "@apollo/client";

const { Header: AntHeader } = Layout;
const { Option } = Select

const BasicHeader = ({t, changeLanguage, currentLanguage, onClickContact, logout, user, openTutorial }) => {

  const [ isProfileMenuVisible, setProfileMenuVisible ] = useState(false)
  const [ isAdvancedModalVisible, setAdvancedModalVisible ] = useState(false)
  const [ drawerOpen, setDrawerOpen ] = useState(false)
  const [ accountModal, setAccontModal ] = useState()

  const createStripeAccount = async () => {
    let { data: stripeData } = await apolloClient.query({
      query: CREATE_STRIPE_ACCOUNT,
      fetchPolicy: 'no-cache',
      variables: {
        email: user.email,
        returnUrl: window.location.href,
        stripeAccountId: user.stripeAccountId,
        uid: user.uid,
        phone: user.phone
      }
    })
    window.location.href = stripeData.createStripeAccount
  }

  const profileMenu = (
    <div>
      {user && user.payoutMode !== 'stripe-done' && <div onClick={createStripeAccount} className="getpaid"><i className="flaticon-coin-stack" /> {t('Get paid fast')}</div>}
      {user && user.bank && <div onClick={() => setAccontModal('bank')}><i className="flaticon-coin-stack" /> {t('Edit bank details')}</div>}
      <div onClick={() => setAccontModal('profile')}><i className="flaticon-avatar" /> {t('Edit account details')}</div>
      <div onClick={() => setAccontModal('password')}><i className="flaticon-padlock" /> {t('Change password')}</div>
      <label>{t('CUSTOMER SERVICE')}</label>
      <div className="whatsapp" onClick={() => onClickContact('WhatsApp')}><WhatsAppOutlined /> {t('On')} WhatsApp</div>
      <div className="phone" onClick={() => onClickContact('Phone')}><PhoneOutlined /> 012 3456 7890</div>
      <div className="logout" onClick={logout}><i className="flaticon-logout" /> {t('Sign out')}</div>
    </div>
  )

  return (
    <div>
      <AntHeader className="basic-header">
        <img src={logo} />
        <h1>F-Guide Basic</h1>
        <Button onClick={() => setAdvancedModalVisible(true)}>
          <p>{t('GO PRO • FOREVER FREE')}</p> 
          <img src={rich} /> 
          <RightOutlined />
        </Button>
        <p onClick={openTutorial}>{t('learn how to make money smarter')}</p>
        <Select
          dropdownClassName="select-dropdown"
          className="select-language"
          value={languages.find(lang => lang.code === currentLanguage).code}
          onChange={val => changeLanguage(val)}
        >
          {languages.map(lang => <Option value={lang.code} key={lang.code}>{lang.name}</Option>)}
        </Select>

        <Desktop>
          <Popover 
            placement="bottomRight" 
            content={profileMenu} 
            overlayClassName="profile-menu"
            onVisibleChange={visible => setProfileMenuVisible(visible)}
          >
            <div className={`header-menu ${isProfileMenuVisible && 'open'}`}>
              <img src={isProfileMenuVisible ? avatar_open : avatar} />
              <CaretDownOutlined />
            </div>
          </Popover>
        </Desktop>
        <Mobile>
          <div onClick={() => setDrawerOpen(true)} className={`header-menu ${isProfileMenuVisible && 'open'}`}>
            <img src={isProfileMenuVisible ? avatar_open : avatar} />
          </div>
        </Mobile>
      </AntHeader>

      <Mobile>
        <Drawer
          isOpen={drawerOpen}
          className="menu-drawer"
          buttons={[
            {
              isLabel: true,
              text: t('LANGUAGE'),
            },
            {
              content: (
                <div className="language-selector">
                  {languages
                    .filter(lang => lang.code !== currentLanguage)
                    .map( lang => <div onClick={() => changeLanguage(lang.code)}>{lang.name}</div>)
                  }
                </div>
              )
            },
            {
              text: t('Get paid fast'),
              icon: <i className="flaticon-coin-stack" />,
              action: () => createStripeAccount,
              hidden: user && user.payoutMode === 'stripe-done',
              className: 'getpaid'
            },
            {
              text: t('Edit account details'),
              icon: <i className="flaticon-avatar" />,
              action: () => setAccontModal('profile')
            },
            {
              text: t('Change password'),
              icon: <i className="flaticon-padlock" />,
              action: () => setAccontModal('password')
            },
            {
              isLabel: true,
              text: t('CUSTOMER SERVICE')
            },
            {
              text: t('On Whatsapp'),
              icon: <WhatsAppOutlined />,
              action: () => onClickContact('WhatsApp'),
              className: 'whatsapp'
            },
            {
              text: '012 2345 5678',
              icon: <PhoneOutlined />,
              action: () => onClickContact('Phone'),
              className: 'phone'
            },
            {
              text: t('Sign out'),
              icon: <i className="flaticon-logout" />,
              className: 'signout',
              action: () => onClickContact('Phone')
            },
          ]}
          t={t}
          onClose={() => setDrawerOpen(false)}
        />
      </Mobile>

      <GoAdcancedModal
        t={t}
        isVisible={isAdvancedModalVisible}
        closeModal={() => setAdvancedModalVisible(false)}
        uid={user && user.uid}
        openTutorial={() => {
          setAdvancedModalVisible(false)
          openTutorial()
        }}
        seenAdvanced={user && user.seenAdvanced}
      />

      <AccountModal
        t={t}
        uid={user && user.uid}
        mode={accountModal}
        setMode={setAccontModal}
      />
    </div>
  )
}

export default BasicHeader;

const CREATE_STRIPE_ACCOUNT = gql`
  query createStripeAccount($email: String, $stripeAccountId: String, $returnUrl: String, $uid: String, $phone: String, $account_holder: String, $account_number: String, $bank: String) {
    createStripeAccount(email: $email, stripeAccountId: $stripeAccountId, returnUrl: $returnUrl, uid: $uid, phone: $phone, account_holder: $account_holder, account_number: $account_number, bank: $bank)
  }
`