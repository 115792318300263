import React from 'react'
import { Modal, Button } from 'antd'
import money from '../../../assets/illustrations/money.png'
import './goAdvancedModal.module.scss'
import { useRouter } from 'next/router'
import withApollo, { apolloClient } from '../../utils/graphql'
import { gql } from "@apollo/client";

const GoAdcancedModal = ({ t, isVisible, closeModal, openTutorial, uid, seenAdvanced }) => {

  const router = useRouter()

  const goAdvanced = async () => {
    await apolloClient.mutate({
      mutation: UPDATE_USER,
      variables: {
        uid,
        mode: 'advanced'
      },
    })
    router.push(seenAdvanced ? '/?switch=true' :'/?intro=true')
  }

  return (
    <Modal
      wrapClassName="go-advanced-modal"
      visible={isVisible}
      onCancel={closeModal}
      footer={false}
      width={405}
    >
      <img src={money} />
      <div className="padding">
        <p>{t('You are currently using FGuide in its most straight-forward yet effective format. What you have yet to discover are all the powerful tools built within it to strategise your marketing approaches for more efficient returns.')}</p>
        <p>{t('We will teach you how and don’t worry, you can easily switch back to the basic format of FGuide.')}</p>
        <Button onClick={openTutorial} className="learn">{t('Learn how to use advanced')}</Button>
        <Button onClick={goAdvanced} className="goadvanced">{t('I’m a pro! Go straight to advanced')}</Button>
        <Button onClick={closeModal} className="cancel">{t('Cancel')}</Button>
      </div>
    </Modal>
  )
}

export default withApollo({ ssr: true })(GoAdcancedModal);

const UPDATE_USER = gql`
  mutation UdateUser(
    $uid: String,
    $mode: String,
  ) {
    updateUser(user: {
      uid: $uid,
      mode: $mode
    })
  }
`