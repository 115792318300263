import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Checkbox, Select } from 'antd'
import './tutorial.module.scss'
import { LeftCircleFilled, RightCircleFilled, RightOutlined, LeftOutlined } from "@ant-design/icons";
import content from './content/content'
import { gql } from "@apollo/client";
import { withTranslation } from '../../../i18next'
import { apolloClient } from '../../utils/graphql';
const { Option } = Select

const Tutorials = ({t, page, closeModal, auth}) => {

  const [ dontShow, setDontShow ] = useState(false)
  const [ currentIndex, setCurrentIndex ] = useState(0)

  useEffect(() => {
    if (page) {
      let index = content.findIndex(item => page.includes(item.page))
      if (index !== -1) {
        setCurrentIndex(index)
      }
    }
  }, [page])

  const close = () => {
    if (dontShow) {
      apolloClient.query({
        query: UPDATE_USER,
        fetchPolicy: 'no-cache',
        variables: {
          dontShowTutorial: true,
          uid: auth.user && auth.user.uid
        }
      })
      auth.setUser({
        ...auth.user,
        dontShowTutorial: true
      })
    }
    closeModal()
  }



  const currentContent = content[currentIndex]

  return (
    <Modal
      width="100%"
      visible={page}
      wrapClassName="tutorials"
      footer={false}
      onCancel={close}
    >
      <div className="head">
        <div>
          <LeftCircleFilled onClick={() => currentIndex !== 0 && setCurrentIndex(val => val -1)} className={currentIndex === 0 && 'inactive'} />
          <RightCircleFilled onClick={() => currentIndex !== content.length - 1 && setCurrentIndex(val => val + 1)} className={currentIndex === content.length - 1 && 'inactive'} />
        </div>
        <Select value={currentIndex} onChange={val => setCurrentIndex(val)}>
          {content.map((item, i) => <Option value={i}>{t(item.title)}</Option>)}
        </Select>
        <Checkbox checked={dontShow} onClick={e => setDontShow(e.target.checked)}>{t('Do not show this again')}</Checkbox>
      </div>
      <Row>
        <Col xs={24} md={13}>
          {currentContent.images.length === 1
            ? <img onClick={() => viewImage(currentContent.images[0], t)} className="single-image" src={currentContent.images[0]} />
            : <Images images={currentContent.images} />
          }
          {currentContent.shortText && <p>{t(currentContent.shortText)}</p>}
        </Col>
        <Col xs={24} md={11} className="text">
        {currentContent.longText && <p>{t(currentContent.longText)}</p>}
        {currentContent.steps && <Steps steps={currentContent.steps} t={t} />}
        </Col>
        </Row>
    </Modal>
  )
}

const viewImage = (src, t) => {
  Modal.info({
    className: 'image-preview',
    icon: false,
    okText: t('Close'),
    content: (
      <img src={src} />
    )
  })
}

const Images = ({images}) => {

  const [ currentIndex, setCurrentIndex ] = useState(0)

  return (
    <div className="images">
      <img onClick={() => viewImage(images[currentIndex], t)} src={images[currentIndex]} className="image" />
      {currentIndex !== 0 && <div onClick={() => setCurrentIndex(val => val - 1)} className="arrow left"><LeftOutlined /></div>}
      {currentIndex !== images.length - 1 && <div onClick={() => setCurrentIndex(val => val + 1)} className="arrow right"><RightOutlined /></div>}
      <div className="previews">
        {images.map( (img, i) => <img onClick={() => setCurrentIndex(i)} src={img} className={currentIndex === i ? 'preview-img active' : 'preview-img'} />)}
      </div>
    </div>
  )
}

const Steps = ({steps, t}) => {

  const [ currentIndex, setCurrentIndex ] = useState(0)

  return (
    <div className="steps">
      <div className="steps-header">
        {<div onClick={() => currentIndex !== 0 && setCurrentIndex(val => val - 1)} className={currentIndex !== 0 ?"arrow left" : "arrow lefr inactive"}><LeftOutlined /></div>}
        {<div onClick={() => currentIndex !== steps.length - 1 && setCurrentIndex(val => val + 1)} className={currentIndex !== steps.length - 1 ? "arrow right" : "arrow right inactive"}><RightOutlined /></div>}
        <h3>{`${t('Step')} ${currentIndex + 1} ${t('of')} ${steps.length} • ${t(steps[currentIndex].title)}`}</h3>
      </div>
      <p>{t(steps[currentIndex].text)}</p>
    </div>
  )
}


Tutorials.getInitialProps = async () => {
  return { namespacesRequired: ['all'] }
}

export default withTranslation(['all'])(Tutorials)

const UPDATE_USER = gql`
  mutation UdateUser(
    $dontShowTutorial: Boolean,
    $uid: String
  ) {
    updateUser(user: {
      dontShowTutorial: $dontShowTutorial,
      uid: $uid
    })
  }
`

