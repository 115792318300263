import React, { useEffect, useState } from 'react'
import './choosePayout.module.scss'
import { Modal, Drawer} from 'antd'
import {Desktop, Mobile } from '../../utils/responsive'
import { gql, useMutation } from "@apollo/client";
import withApollo, { apolloClient } from '../../utils/graphql'
import compose from '../../utils/compose'
import { useRouter } from 'next/router'
import { withTranslation } from '../../../i18next'
import Choose from './payouts/choosePayout'
import BankDetails from './payouts/bankDetails'
import Success from './payouts/success'

const ChoosePayout = ({ t, isVisible, closeModal, uid }) => {

  const [ user, setUser ] = useState({})
  const [ currentScreen, setCurrentScreen ] = useState()
  const [ hasCommission, setHasCommission ] = useState()
  const [ commissionAmount, setCommissionAmount ] = useState()

  const [updateUser] = useMutation(UPDATE_USER)
  const router = useRouter() 
  
  useEffect(async () => {
    if (isVisible) { 
      let { data: userData } = await apolloClient.query({
        query: GET_USER_BY_ID,
        fetchPolicy: 'network-only',
        variables: {
          id: uid
        }
      })
      setUser(userData.getUserById)
      if (!userData.getUserById.payoutMode) {
        getCommissionAmount()
        setCurrentScreen('choose-payout')
      } else if (router.query.stripe_success && userData.getUserById.payoutMode === 'stripe-done' ) {
        setCurrentScreen('stripe-setup')
      }
    } 
  }, [isVisible])


  const createStripeAccount = async () => {
    let { data: stripeData } = await apolloClient.query({
      query: CREATE_STRIPE_ACCOUNT,
      fetchPolicy: 'no-cache',
      variables: {
        email: user.email,
        returnUrl: window.location.href,
        stripeAccountId: user.stripeAccountId,
        uid: user.uid,
        phone: user.phone
      }
    })
    window.location.href = stripeData.createStripeAccount
  }


  const getCommissionAmount = async () => {
    const { data } = await apolloClient.query({
      query: GET_COMMISSIONS
    })
    if (!data || !data.getCommissions[0]) return setHasCommission(0)
    let amount = data.getCommissions[0].items.reduce((acc, item) => acc + item.amount, 0)
    setCommissionAmount(amount)
  }

  const close = () => {
    router.replace(router.asPath.replace('&stripe_success=true', '').replace('?stripe_success=true', ''), undefined, { shallow: true })
    closeModal()
  }


  const renderBody = () => {
    if (currentScreen === 'choose-payout') {
      return (
        <Choose 
          t={t}
          setupStripe={createStripeAccount}
          enterBankDetails={() => {
            setCurrentScreen('enter-bankdetails')
          }}
          setHasCommission={setHasCommission}
          amount={commissionAmount}
        />
      )
    } else if (currentScreen === 'enter-bankdetails') {
      return (
        <BankDetails
          t={t}
          submitBankDetails={values => {
            updateUser({variables: { uid, payoutMode: 'bank', ...values }})
            setCurrentScreen('bank-setup')
          }}
          goBack={() => setCurrentScreen('choose-payout')}
        />
      )
    } else if (currentScreen === 'stripe-setup') {
      return (
        <Success 
          t={t}
          type="stripe"  
          goBack={closeModal}
        />
      )
    } else if (currentScreen === 'bank-setup') {
      return (
        <Success 
          t={t}
          type="bank"  
          goBack={closeModal}
        />
      )
    }
  }

  const modalVisible = isVisible && ((currentScreen === 'choose-payout' && commissionAmount) || (currentScreen && currentScreen !== 'choose-payout' ))
  if (typeof currentScreen === undefined) return <div />

  return (
    <div>
      <Desktop>
        <Modal
          visible={modalVisible}
          title={null}
          footer={null}
          width={['choose-payout', 'enter-bankdetails'].includes(currentScreen) ? 620 : 408}
          onCancel={close}
          wrapClassName={`payout-modal ${!currentScreen || currentScreen === 'share' ? 'no-x' : ''}`}
        >
          {renderBody()}
        </Modal>
      </Desktop>

      <Mobile>
        <Drawer
          title={null}
          placement="bottom"
          height={'auto'}
          onClose={close}
          className={`payout-modal ${!currentScreen || currentScreen === 'share' ? 'no-x' : ''}`}
          visible={modalVisible}
        >
          {renderBody()}
        </Drawer>
      </Mobile>
    </div>
  )
}

ChoosePayout.getInitialProps = async () => {
  return { namespacesRequired: ['all'] }
}


export default compose(
  withApollo({ ssr: true }),
  withTranslation(['all'])
)(ChoosePayout)

const GET_USER_BY_ID = gql`
  query GetUserById($id: String!) {
    getUserById(id: $id) {
      uid
      name
      company
      phone
      email
      stripeStatus
      role
      status
      stripeAccountId
      bank
      account_holder
      account_number
      payoutMode
    }
  }
`

const CREATE_STRIPE_ACCOUNT = gql`
  query createStripeAccount($email: String, $stripeAccountId: String, $returnUrl: String, $uid: String, $phone: String, $account_holder: String, $account_number: String, $bank: String) {
    createStripeAccount(email: $email, stripeAccountId: $stripeAccountId, returnUrl: $returnUrl, uid: $uid, phone: $phone, account_holder: $account_holder, account_number: $account_number, bank: $bank)
  }
`

const UPDATE_USER = gql`
  mutation UdateUser(
    $payoutMode: String
    $uid: String,
    $account_holder: String,
    $account_number: String,
    $bank: String
  ) {
    updateUser(user: {
      payoutMode: $payoutMode
      uid: $uid,
      account_holder: $account_holder,
      account_number: $account_number,
      bank: $bank
    })
  }
`

const GET_COMMISSIONS = gql`
  query getCommissions {
    getCommissions(onlyMine: true) {
      items {
        amount
        status
      }
    }
  }
`